import React, { useState } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";

const PayPalButton = (props) => {
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);

  // // Example list of items in the cart
  // const mainItems = [
  //   { name: "Item A", price: 10.0, quantity: 2 },
  //   { name: "Item B", price: 5.5, quantity: 1 },
  //   { name: "Item C", price: 8.0, quantity: 3 },
  // ];

  const mainItems = props.items;
  // console.log("Main Items >>> ", mainItems)

  // Calculate total price
  const totalPrice = mainItems.reduce((acc, item) => acc + Number(item.totalAmount) * item.quantity, 0).toFixed(2);
  // console.log("Total Price >>> ", totalPrice)

  const handleApprove = async (order) => {
    setPaidFor(true);
    const transactionId = order.purchase_units[0].payments.captures[0].id;
    await verifyTransaction(transactionId);
    // alert("Payment successful! Order ID: " + orderID);
  };

  const verifyTransaction = async (transactionId) => {
    const PAYPAL_CLIENT_ID = "AavQaWG9pouyqcvTedd1lPLVO6uX9bm5gYnQ0RfCt-o0xqmEkbRyTPFD8GHo2IFUA6hmV9kzCu5o09di";
    const PAYPAL_SECRET = "ELTVbn_n2MKGkmsPShnI0mLlCkYAzFuk9tPA4c2s2ivr11DakJsDSkbT5QxZtS5wk0-GrB94srQUswYI";

    const token = Buffer.from(`${PAYPAL_CLIENT_ID}:${PAYPAL_SECRET}`).toString("base64");

    try {
      const response = await axios.get(`https://api-m.sandbox.paypal.com/v2/payments/captures/${transactionId}`, {
        headers: {
          Authorization: `Basic ${token}`,
          "Content-Type": "application/json",
        },
      });

      // console.log("Transaction Verified:", response.data);
      props.onPaymentSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("Error verifying transaction:", error);
    }
  };

  if (paidFor) {
    return <h2>Thank you for your payment!</h2>;
  }

  return (
    <div className="payBtn_div">
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: "USD",
                  value: totalPrice,
                  breakdown: {
                    item_total: { currency_code: "USD", value: totalPrice },
                  },
                },
                items: mainItems.map((item) => ({
                  sku: item.payableReceivableId,
                  name: item.name,
                  unit_amount: { currency_code: "USD", value: Number(item.totalAmount).toFixed(2) },
                  quantity: item.quantity,
                  category: "DIGITAL_GOODS"
                })),
              },
            ],
          });
        }}
        onApprove={async (data, actions) => {
          try {
            const order = await actions.order.capture();
            // console.log("Order Details >>>> ", order)
            handleApprove(order);
          } catch (error) {
            setError(error);
          }
        }}
        onError={(err) => {
          setError(err);
          console.error("PayPal Checkout Error:", err);
        }}
      />

      {error && <p style={{ color: "red" }}>Error: {error.message}</p>}
    </div>
  );
};

export default PayPalButton;
