import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalButton from "./PayPalButton"
import React from "react";

const initialOptions = {
    clientId: "AavQaWG9pouyqcvTedd1lPLVO6uX9bm5gYnQ0RfCt-o0xqmEkbRyTPFD8GHo2IFUA6hmV9kzCu5o09di",
    currency: "USD",
    intent: "capture",
};

export default function PaypalSandbox(props) {
    return (
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButton total={props.total} items={props.items} onPaymentSuccess={props.onPaymentSuccess} />
        </PayPalScriptProvider>
    );
}