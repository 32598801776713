import React from "react";
import { Link } from "react-router-dom";
import LotteLoader from "../../../Loader/LotteLoader";
import { consoleLog, SetDateFormat, SetTimeFormat } from "../../../../services/common-function";
import { ApiCall } from "../../../../services/middleware";
import { ErrorCode } from "../../../../services/constant";
import { Decoder } from "../../../../services/auth";
import { IMAGE_PATH_ONLY } from "../../../../services/config/api_url";
import { toast } from "react-toastify";

export default class DocumentStatusView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: true,
            docId: "",
            allContractListData: []
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        this.load();
    }

    load = async () => {
        let mainData = this.props.location,
            preData = mainData.state;

        consoleLog("Document Data", preData);
        this.setState({
            docId: preData
        })
        let req = {
            documentId: preData
        }
        this.setState({ isLoad: false })
        let res = await ApiCall("getUsersContractsBydocId", req);

        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            this.setState({ isLoad: false })
            let payload = Decoder.decode(res.data.payload);
            consoleLog("Document List Details >>> ", payload.data);
            if (payload.data.length > 0) {
                this.setState({
                    allContractListData: payload.data
                })
            }
        } else {
            this.setState({ isLoad: false })
        }
    }

    onDownload = (pos) => {
        if (this.state.allContractListData[pos].documentPath) {
            consoleLog("Document::", IMAGE_PATH_ONLY + this.state.allContractListData[pos].documentPath)
            window.open(
                IMAGE_PATH_ONLY + this.state.allContractListData[pos].documentPath,
                "_blank"
            );
        } else {
            toast.error("No Document Found");
        }
    };
    render() {
        return (
            <div className="component-wrapper">
                <div className="listing-component-app">
                    <div
                        className="vn_frm"
                        style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
                    >
                        {" "}
                        <Link to="/adminDashboard">Dashboard</Link> /{" "}
                        <Link to="/adminDocuments">Documents</Link> / Document Status
                    </div>
                    <h3 className="dcs">Document Status</h3>
                    <div className="table-listing-app" hidden={!this.state.isLoad}>
                        <LotteLoader />
                    </div>
                    <div className="table-listing-app" hidden={this.state.isLoad}>
                        <div className="table-responsive">
                            <table
                                width="100%"
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                className="docStatusTable"
                            >
                                <tr>
                                    <th style={{ width: "25%" }}>Username</th>
                                    <th style={{ width: "25%" }}>email</th>
                                    <th style={{ width: "25%" }}>Last Modified On</th>
                                    <th style={{ width: "20%" }}>Status</th>

                                </tr>
                                {this.state.allContractListData.length > 0 ? (
                                    this.state.allContractListData.map((data, i) => (
                                        <tr key={i}>
                                            <td colspan="4">
                                                <div className="tble-row">
                                                    <table
                                                        width="100%"
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                    >
                                                        <tr>
                                                            <td style={{ width: "25%" }} >{data.username}</td>
                                                            <td style={{ width: "25%" }} >{data.email}</td>
                                                            <td style={{ width: "25%" }}>
                                                                {data.requestDate == null ? <>N/A</> : <>
                                                                    {SetDateFormat(data.requestDate)} |{" "}
                                                                    {SetTimeFormat(data.requestDate)}
                                                                </>}
                                                            </td>
                                                            {data.verificationStatus == null ? <td style={{ width: "20%" }}> N/A</td> : <>
                                                                {data.verificationStatus === 0 ? (
                                                                    <td style={{ width: "20%" }} >
                                                                        <span className="Pending_btn" onClick={() => this.onDownload(i)}>
                                                                            SENT
                                                                        </span>
                                                                    </td>
                                                                ) : data.verificationStatus === 1 ? (
                                                                    <td style={{ width: "20%" }}>
                                                                        <span className="approve_status_btn" onClick={() => this.onDownload(i)}>
                                                                            SIGNED
                                                                        </span>
                                                                    </td>
                                                                ) : (
                                                                    <td style={{ width: "20%" }}>
                                                                        <span className="declined_btn" onClick={() => this.onDownload(i)}>
                                                                            Declined
                                                                        </span>
                                                                    </td>
                                                                )}
                                                            </>}
                                                        </tr>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <React.Fragment>
                                        <tr style={{ textAlign: "center" }}>
                                            <td colSpan="4">
                                                <center style={{ fontSize: "20px" }}>
                                                    No data found !!!
                                                </center>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}