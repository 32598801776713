import React from "react";
import history from "../../../history";
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import $ from "jquery";
import { CommonData, ErrorCode } from "../../../services/constant";
import { consoleLog, decimalValue, getLanguageArray, getLookUpDataFromAPI } from "../../../services/common-function";
import { AlertMessage, ImageName } from "../../../enums";
import { ApiCall } from "../../../services/middleware";
import { Decoder } from "../../../services/auth";
import { ToastContainer, toast } from "react-toastify";
import ReactLoader from "../../Loader";
import { SmallSelectBox, SelectBox } from "../SharedComponents/inputText";
import { MultiSelectBox, PaginationDropdown } from "../SharedComponents/inputText";

const hdfArr = [
    {
        label: "Hour(s)",
        value: "Hour",
    },
    {
        label: "Half Business Day(s)",
        value: "Half day",
    },
    {
        label: "Full Business Day(s)",
        value: "Full day",
    }
];

const hdArr = [
    {
        label: "Hour(s)",
        value: "Hour",
    },
    {
        label: "Business Day(s)",
        value: "Days",
    }
];

const mhArr = [
    {
        label: "Minute(s)",
        value: "Minutes",
    },
    {
        label: "Hour(s)",
        value: "Hours",
    }
];

const mhfArr = [
    {
        label: "Minute(s)",
        value: "Minutes",
    },
    {
        label: "Hour(s)",
        value: "Hours",
    },
    {
        label: "Flat Rate",
        value: "Flat Rate",
    }
];

const rpmArr = [
    {
        label: "Per Mile",
        value: "Per Mile",
    },
    {
        label: "Flat Rate",
        value: "Flat Rate",
    }
];


export default class GlobalRateCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showId: "",
            isInterpretation: true,
            isLoad: true,
            current_page: 1,
            total_page: 10,
            limit: 20,
            offset: 0,
            display: "",

            allLanguageArr: [],
            allAppointmentType: [],
            allJobType: [],


            clientId: "0",
            clientName: "",
            vendorId: "",
            vendorName: "",
            allVendorLanguage: [],
            allSourceLanguage: [],
            allTargetLanguage: [],
            selectedSourceLanguage: {
                label: "English",
                value: 110
            },
            selectedTargetLanguage: {},
            allTargetLanguageForAdd: [],
            selectedtargetLanguageForAdd: [],
            targetLanguageForAdd: [],
            sourceLanguage: 110,
            targetLanguage: "",
            rateCardData: [
                {
                    appointmentType: "",
                    industryType: "",
                    regularRateHour: "",
                    regularRateHalfDay: "",
                    regularRateFullDay: "",
                    minimumDurationRate: "",
                    minimumDurationUnit: "",
                    billingIncrementRate: "",
                    billingIncrementUnit: "",
                    nightWeekendRate: "",
                    nightWeekendUnit: "",
                    mileageRate: [{
                        from: "",
                        to: "",
                        ratePerMile: "",
                        ratePerMileUnit: "",
                        selectedRatePerMileUnit: {}
                    }],
                    cancelRateChart: [{
                        from: "",
                        to: "",
                        rate: "",
                        rateUnit: "Perc",
                        selectedRatePerMileUnit: {}
                    }],
                    cancelRate: "",
                    cancelUnit: "",
                    rushPolicyRate: "",
                    rushPolicyUnit: "",
                    rushFeeRate: "",
                    rushFeeUnit: "",
                    disableAppointment: false,
                    disableJob: false,
                    selectedAppointmentType: {},
                    selectedIndustryType: {}
                }
            ],


            interpretationData: [],
            interpretationModal: false,
            searchValue: "",

            targetLanguageId: "",
            sourceLanguageId: "",

            editModalData: [],
            isEditInterpretation: false,

            isAdd: true,
            isAllLang: true,
            cardRowPosition: 0,
            bookingFeeArr: [],
            cancelFeeArr: [],

            selectedDisplayData: {
                label: "20",
                value: "20",
            },
            loader: false
        };

    }

    loaderChange = async (type) => {
        this.setState({
            loader: type
        })
    }

    componentDidMount() {

        let data = {
            vendorId: this.state.clientId,
            serviceId: 45,
            limit: this.state.limit,
            offset: this.state.offset
        }
        this.getRateList(data);
        document.getElementById("backdrop").style.display = "none";

        window.scrollTo(0, 0);

        var classInstance = this;

        var editModal = document.getElementById("interpretationModal");
        var addModal = document.getElementById("addRateCardModal");
        var mileageModal = document.getElementById("mileage-modal");
        var cancelRateModal = document.getElementById("cancelRate-modal");
        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
            if (event.target === editModal) {
                classInstance.closeInterpretationModal();
            } else if (event.target === addModal) {
                classInstance.closeAddInterpretationModal();
            } else if (event.target === mileageModal) {
                classInstance.closeMileageModal();
            } else if (event.target === cancelRateModal) {
                classInstance.closeCancelRateModal();
            }
        };
        this.onLoad();

        window.$(".verificaiton-doc-tab ul li").on("click", function () {
            $(".verificaiton-doc-tab ul li").removeClass("active");
            $(this).addClass("active");
            $("div").removeClass("activeLnk");
            $("div[id=" + $(this).attr("data-related") + "]").addClass("activeLnk");
        });
        // this.load();
        window.$(".filterTab ul li").on("click", function () {
            $(".filterTab ul li").removeClass("active");
            $(this).addClass("active");
            $("div").removeClass("activeLnk1");
            $("div[id=" + $(this).attr("data-related") + "]").addClass("activeLnk1");
        });
    }

    onLoad = async () => {
        let allLookupValue = [],
            allAppointmentType = [{
                label: "All",
                value: "all"
            }],
            allLanguageArr = [],
            allJobType = [{
                label: "All",
                value: "all"
            }];


        allLookupValue = await getLookUpDataFromAPI();

        allLanguageArr = await getLanguageArray();
        // consoleLog("All Language:::", allLanguageArr);

        if (allLanguageArr.length > 0) {
            let crr = [{
                label: "All",
                value: "all"
            }];

            allLanguageArr.map((tar) => {
                crr.push({
                    label: tar.label,
                    value: tar.value
                })
            });
            this.setState({
                allTargetLanguageForAdd: crr,
            })
        }


        allLookupValue.INDUSTRY_TYPE.map((data) => {
            allJobType.push({
                label: data.name,
                value: data.id
            })
        })

        allLookupValue.SCHEDULE_TYPE.map((data) => {
            if (data.id === 63) {
                allAppointmentType.push({
                    label: <div><img src={ImageName.IMAGE_NAME.F2F} height="20px" width="20px" style={{ float: "Left" }} /><span style={{ paddingLeft: "5%" }}>{data.name}</span></div>,
                    value: data.id
                })
            } else if (data.id === 64) {
                allAppointmentType.push({
                    label: <div><img src={ImageName.IMAGE_NAME.VRI_ICON} height="20px" width="20px" style={{ float: "Left" }} /><span style={{ paddingLeft: "5%" }}>{data.name}</span></div>,
                    value: data.id
                })
            } else if (data.id === 65) {
                allAppointmentType.push({
                    label: <div><img src={ImageName.IMAGE_NAME.OPI_ICON} height="20px" width="20px" style={{ float: "Left" }} /><span style={{ paddingLeft: "5%" }}>{data.name}</span></div>,
                    value: data.id
                })
            }
        })

        this.setState({
            allAppointmentType: allAppointmentType,
            allLanguageArr: allLanguageArr,
            allJobType: allJobType
        })
    }

    getRateList = async (data) => {
        let res = await ApiCall("getGlobalRatecardList", data);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            let totalPage = Math.ceil(payload.data.total / this.state.limit);
            consoleLog("RateCardList>>", payload.data.ratecardInfo);
            if (payload.data.ratecardInfo) {
                if (payload.data.ratecardInfo) {
                    if (payload.data.ratecardInfo.length > 0) {
                        this.setState({
                            interpretationData: payload.data.ratecardInfo,
                            total_page: totalPage,
                        })
                    } else {
                        this.setState({
                            interpretationData: []
                        })
                    }
                }
            }
            this.setState({
                isLoad: false
            })
        } else {
            toast.error(res.message)
        }
    }

    onChangeLimit = async (dat) => {
        this.setState({
            limit: parseInt(dat.value),
            selectedDisplayData: dat,
            current_page: 1,
        });
        let limit = parseInt(dat.value),
            // offset = (this.state.current_page - 1) * parseInt(dat.value);
            offset = "0";
        let data = {
            vendorId: this.state.clientId,
            serviceId: 45,
            limit: limit.toString(),
            offset: offset,
        };

        this.getRateList(data);
    };

    // getVendorLanguage = async (data) => {
    //     let res = await ApiCall("getUserLanguagesByServiceId", data);
    //     if (
    //         res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
    //         res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    //     ) {
    //         let payload = Decoder.decode(res.data.payload);
    //         if (payload.data.languageInfo) {
    //             if (payload.data.languageInfo.length > 0) {
    //                 let arr = [];
    //                 payload.data.languageInfo.map((lan) => {
    //                     arr.push({
    //                         label: lan.name,
    //                         value: lan.id
    //                     });
    //                     if (lan.target && lan.target.length > 0) {
    //                         let brr = [],
    //                             crr = [{
    //                                 label: "All",
    //                                 value: "all"
    //                             }];
    //                         let obj = {};
    //                         obj = {
    //                             label: lan.target[0].name,
    //                             value: lan.target[0].id
    //                         }
    //                         lan.target.map((tar) => {
    //                             brr.push({
    //                                 label: tar.name,
    //                                 value: tar.id
    //                             });

    //                             crr.push({
    //                                 label: tar.name,
    //                                 value: tar.id
    //                             })
    //                         });
    //                         this.setState({
    //                             allTargetLanguage: brr,
    //                             allTargetLanguageForAdd: crr,
    //                             selectedTargetLanguage: obj,
    //                             targetLanguage: obj.value
    //                         })
    //                     }
    //                 });
    //                 this.setState({
    //                     allSourceLanguage: arr,
    //                     allVendorLanguage: payload.data.languageInfo
    //                 })
    //             }
    //         }
    //     }
    // }

    deleteRate = async (value) => {
        // consoleLog("DeletId>>>", value);
        let data = {
            id: value
        }
        let res = await ApiCall("deleteVendorRatecard", data);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            toast.success(AlertMessage.MESSAGE.RATE_CARD.DELETE_RATE_SUCCESS);
            let obj = {
                vendorId: this.state.clientId
            }
            this.getRateList(obj)
        }
    }

    addNewRow = () => {
        let arr = this.state.rateCardData;
        let errorCounter = 0;

        let disableAppointment = false,
            disableJob = false;
        if (arr.length > 0) {
            arr.map((ar, i) => {
                if (ar.appointmentType === 'all') {
                    disableAppointment = true;
                }
                if (ar.industryType === 'all') {
                    disableJob = true;
                }
                if (ar.appointmentType === 'all' && ar.industryType === 'all') {
                    errorCounter = 1;
                }
            })
        }

        if (errorCounter == 0) {
            arr.push(
                {
                    appointmentType: "",
                    industryType: "",
                    regularRateHour: "",
                    regularRateHalfDay: "",
                    regularRateFullDay: "",
                    minimumDurationRate: "",
                    minimumDurationUnit: "",
                    billingIncrementRate: "",
                    billingIncrementUnit: "",
                    nightWeekendRate: "",
                    nightWeekendUnit: "",
                    mileageRate: [{
                        from: "",
                        to: "",
                        ratePerMile: "",
                        ratePerMileUnit: "",
                        selectedRatePerMileUnit: {}
                    }],
                    cancelRateChart: [{
                        from: "",
                        to: "",
                        rate: "",
                        rateUnit: "Perc",
                        selectedRatePerMileUnit: {}
                    }],
                    cancelRate: "",
                    cancelUnit: "",
                    rushPolicyRate: "",
                    rushPolicyUnit: "",
                    rushFeeRate: "",
                    rushFeeUnit: "",
                    disableAppointment: disableAppointment,
                    disableJob: disableJob,
                    selectedAppointmentType: {},
                    selectedIndustryType: {}
                }
            );
            this.setState({
                rateCardData: arr
            })
        } else {
            toast.error("Cannot Add ")
        }
    }

    onSourceLangChange = (data) => {
        this.setState({
            sourceLanguage: data.value,
            selectedSourceLanguage: data
        });
    };

    onTargetLangChangeForAdd = (value) => {
        let arr = [],
            crr = [],
            brr = [{
                label: "All",
                value: "all"
            }];
        if (value.length > 0) {
            value.map((data) => {
                if (data.value === "all") {
                    arr = [];
                    crr = [{
                        label: "All",
                        value: "all"
                    }];
                    this.state.allLanguageArr.map((ln) => {
                        arr.push(ln.value)
                    });
                    this.setState({
                        allTargetLanguageForAdd: [],
                        isAllLang: true
                    })
                } else {
                    arr.push(data.value);
                    crr = value;
                    this.setState({
                        isAllLang: false
                    })
                }
            });
        } else {
            this.state.allLanguageArr.map((ln) => {
                brr.push(ln);
            });
            this.setState({
                allTargetLanguageForAdd: brr,
            })
        }
        // consoleLog("selected target for add :", arr)
        this.setState({
            selectedtargetLanguageForAdd: crr,
            targetLanguageForAdd: arr
        })
    }


    onTargetLangChange = async (data) => {
        if (data.value == "all") {
            this.setState({
                isAllLang: true,
                targetLanguage: data.value,
                selectedTargetLanguage: data
            });
        } else {
            this.setState({
                isAllLang: false,
                targetLanguage: data.value,
                selectedTargetLanguage: data
            });
        }

        // this.setState({
        //     targetLanguage: data.value,
        //     selectedTargetLanguage: data
        // });

        // let obj = {
        //     vendorId: this.state.clientId,
        //     serviceId: 45,
        //     sourceLanguage: this.state.sourceLanguage,
        //     targetLanguage: data.value
        // }
        // let res = await ApiCall("getRatecardByLanguage", obj);
        // if (
        //     res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        //     res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        // ) {
        //     let payload = Decoder.decode(res.data.payload);
        //     let arr = [];
        //     if (payload.data.ratecardInfo.rateCardData.length > 0) {
        //         payload.data.ratecardInfo.rateCardData.map((data) => {
        //             let selectedAppointment = {},
        //                 selectedIndustryType = {},
        //                 selectedMinimumDurationUnit = {},
        //                 selectedBillingIncrementUnit = {},
        //                 selectedNightWeekendUnit = {},
        //                 selectedCancelUnit = {},
        //                 selectedRushPolicyUnit = {},
        //                 selectedRushFeeUnit = {};

        //             this.state.allAppointmentType.map((ap) => {
        //                 if (data.appointmentType === ap.value) {
        //                     selectedAppointment = ap;
        //                 }
        //             });

        //             this.state.allJobType.map((jb) => {
        //                 if (data.industryType === jb.value) {
        //                     selectedIndustryType = jb;
        //                 }
        //             });

        //             hdfArr.map((aa) => {
        //                 if (data.minimumDurationUnit === aa.value) {
        //                     selectedMinimumDurationUnit = aa;
        //                 }
        //             });

        //             mhArr.map((aa) => {
        //                 if (data.billingIncrementUnit === aa.value) {
        //                     selectedBillingIncrementUnit = aa;
        //                 }
        //             });

        //             mhfArr.map((aa) => {
        //                 if (data.nightWeekendUnit === aa.value) {
        //                     selectedNightWeekendUnit = aa;
        //                 }
        //             });

        //             hdArr.map((aa) => {
        //                 if (data.cancelUnit === aa.value) {
        //                     selectedCancelUnit = aa;
        //                 }
        //             });

        //             hdArr.map((aa) => {
        //                 if (data.rushPolicyUnit === aa.value) {
        //                     selectedRushPolicyUnit = aa;
        //                 }
        //             });

        //             mhfArr.map((aa) => {
        //                 if (data.rushFeeUnit === aa.value) {
        //                     selectedRushFeeUnit = aa;
        //                 }
        //             });

        //             arr.push({
        //                 appointmentType: data.appointmentType,
        //                 selectedAppointmentType: selectedAppointment,
        //                 industryType: data.industryType,
        //                 selectedIndustryType: selectedIndustryType,
        //                 regularRateHour: data.regularRateHour,
        //                 regularRateHalfDay: data.regularRateHalfDay,
        //                 regularRateFullDay: data.regularRateFullDay,
        //                 minimumDurationRate: data.minimumDurationRate,
        //                 minimumDurationUnit: data.minimumDurationUnit,
        //                 selectedMinimumDurationUnit: selectedMinimumDurationUnit,
        //                 billingIncrementRate: data.billingIncrementRate,
        //                 billingIncrementUnit: data.billingIncrementUnit,
        //                 selectedBillingIncrementUnit: selectedBillingIncrementUnit,
        //                 nightWeekendRate: data.nightWeekendRate,
        //                 nightWeekendUnit: data.nightWeekendUnit,
        //                 selectedNightWeekendUnit: selectedNightWeekendUnit,
        //                 mileageRate: data.mileageRate,
        //                 cancelRate: data.cancelRate,
        //                 cancelUnit: data.cancelUnit,
        //                 selectedCancelUnit: selectedCancelUnit,
        //                 rushPolicyRate: data.rushPolicyRate,
        //                 rushPolicyUnit: data.rushPolicyUnit,
        //                 selectedRushPolicyUnit: selectedRushPolicyUnit,
        //                 rushFeeRate: data.rushFeeRate,
        //                 rushFeeUnit: data.rushFeeUnit,
        //                 selectedRushFeeUnit: selectedRushFeeUnit
        //             })
        //         });
        //     } else {
        //         arr = this.state.rateCardData;
        //     }
        //     // consoleLog("RateCArdArray>>>", arr)
        //     this.setState({
        //         rateCardData: arr
        //     })
        // }
    };


    appointmentTypeChange = (value, i) => {
        let arr = this.state.rateCardData;
        let brr = [];
        arr[i].selectedAppointmentType = value;
        arr[i].appointmentType = value.value;
        // if (value.value === 'all') {
        //     brr.push(arr[i]);
        //     // console.log("brr", brr);
        //     arr = [];
        //     arr = brr;
        //     this.setState({
        //         rateCardData: arr
        //     })
        // } else {

        //     this.setState({
        //         rateCardData: arr
        //     })
        // }
        this.setState({
            rateCardData: arr
        })
    }

    handleJobType = (value, i) => {
        let arr = this.state.rateCardData;
        //let brr = [];
        arr[i].selectedIndustryType = value;
        arr[i].industryType = value.value;
        // if (value.value === 'all') {
        //     brr.push(arr[i]);
        //     // console.log("brr", brr);
        //     arr = [];
        //     arr = brr;
        //     this.setState({
        //         rateCardData: arr
        //     })
        // } else {

        //     this.setState({
        //         rateCardData: arr
        //     })
        // }
        this.setState({
            rateCardData: arr
        })
    }

    regularRateHourChange = (i, e) => {
        let arr = this.state.rateCardData;
        if (decimalValue(e.target.value)) {
            arr[i].regularRateHour = e.target.value;
            this.setState({
                rateCardData: arr
            });
        }
    }

    handleRegularRateHalfDay = (e, i) => {
        let arr = this.state.rateCardData;
        if (decimalValue(e.target.value)) {
            arr[i].regularRateHalfDay = e.target.value;
            this.setState({
                rateCardData: arr
            })
        }
    }

    handleRegularRateFullDay = (e, i) => {
        let arr = this.state.rateCardData;
        if (decimalValue(e.target.value)) {
            arr[i].regularRateFullDay = e.target.value;
            this.setState({
                rateCardData: arr
            })
        }
    }

    handleMinimumDurationrate = (e, i) => {
        let arr = this.state.rateCardData;
        if (decimalValue(e.target.value)) {
            arr[i].minimumDurationRate = e.target.value;
            this.setState({
                rateCardData: arr
            })
        }
    }

    handleMinimumDurationUnit = (value, i) => {
        let arr = this.state.rateCardData;
        arr[i].minimumDurationUnit = value.value;
        this.setState({
            rateCardData: arr
        })
    }

    handleBillingRate = (e, i) => {
        let arr = this.state.rateCardData;
        if (decimalValue(e.target.value)) {
            arr[i].billingIncrementRate = e.target.value;
            this.setState({
                rateCardData: arr
            })
        }
    }

    handleBillingUnit = (value, i) => {
        let arr = this.state.rateCardData;
        arr[i].billingIncrementUnit = value.value;
        this.setState({
            rateCardData: arr
        })
    }

    handleNightPerWeekendRate = (e, i) => {
        let arr = this.state.rateCardData;
        if (decimalValue(e.target.value)) {
            arr[i].nightWeekendRate = e.target.value;
            this.setState({
                rateCardData: arr
            })
        }
    }

    handleNightPerWeekendUnit = (value, i) => {
        let arr = this.state.rateCardData;
        arr[i].nightWeekendUnit = value.value;
        this.setState({
            rateCardData: arr
        })
    }

    handleCancelRate = (e, i) => {
        let arr = this.state.rateCardData;
        if (decimalValue(e.target.value)) {
            arr[i].cancelRate = e.target.value;
            this.setState({
                rateCardData: arr
            })
        }
    }

    handleCancelUnit = (value, i) => {
        let arr = this.state.rateCardData;
        arr[i].cancelUnit = value.value;
        this.setState({
            rateCardData: arr
        })
    }

    handleRushPolicyrate = (e, i) => {
        let arr = this.state.rateCardData;
        if (decimalValue(e.target.value)) {
            arr[i].rushPolicyRate = e.target.value;
            this.setState({
                rateCardData: arr
            })
        }
    }

    handleRushPolicyUnit = (value, i) => {
        let arr = this.state.rateCardData;
        arr[i].rushPolicyUnit = value.value;
        this.setState({
            rateCardData: arr
        })
    }

    handleRushFeeRate = (e, i) => {
        let arr = this.state.rateCardData;
        if (decimalValue(e.target.value)) {
            arr[i].rushFeeRate = e.target.value;
            this.setState({
                rateCardData: arr
            })
        }
    }

    handleRushFeeUnit = (value, i) => {
        let arr = this.state.rateCardData;
        arr[i].rushFeeUnit = value.value;
        this.setState({
            rateCardData: arr
        })
    }


    onSubmitRate = async () => {

        let errorCounter = 0;

        if (this.state.isAdd) {
            if (this.state.targetLanguageForAdd.length == 0) {
                toast.error(AlertMessage.MESSAGE.RATE_CARD.TARGET_LANG_BLANK);
                errorCounter++;
                return false;
            }
        } else {
            if (this.state.targetLanguage === "") {
                toast.error(AlertMessage.MESSAGE.RATE_CARD.TARGET_LANG_BLANK);
                errorCounter++;
                return false;
            }
        }


        this.state.rateCardData.map((data, i) => {
            if (data.appointmentType === "" ||
                data.industryType === "" ||
                data.regularRateHour === "" ||
                data.regularRateHalfDay === "" ||
                data.regularRateFullDay === "" ||
                data.minimumDurationRate === "" ||
                data.minimumDurationUnit === "" ||
                data.billingIncrementRate === "" ||
                data.billingIncrementUnit === "" ||
                data.nightWeekendRate === "" ||
                data.nightWeekendUnit === "" ||
                data.cancelRateChart.length == 0 ||
                data.mileageRate.length == 0 ||
                // data.cancelRate === "" ||
                // data.cancelUnit === "" ||
                data.rushPolicyRate === "" ||
                data.rushPolicyUnit === "" ||
                data.rushFeeRate === "" ||
                data.rushFeeUnit === ""
            ) {
                toast.error(AlertMessage.MESSAGE.RATE_CARD.ALL_FIELDS_REQUIRED + "at row " + Number(i + 1));
                errorCounter++;
                return false;
            } else if (data.cancelRateChart.length > 0) {
                let er = 0;
                data.cancelRateChart.map((mil, j) => {
                    if (mil.from === "" ||
                        mil.to === "" ||
                        mil.rate === "") {
                        er++;
                        return false;
                    }
                })
                if (er !== 0) {
                    toast.error(AlertMessage.MESSAGE.RATE_CARD.CANCEL_RATE_BLANK + " at row " + Number(i + 1));
                    errorCounter++;
                    return false;
                }
            } else if (data.mileageRate.length > 0) {
                let er = 0;
                data.mileageRate.map((mil, j) => {
                    if (mil.from === "" ||
                        mil.to === "" ||
                        mil.ratePerMile === "" ||
                        mil.ratePerMileUnit === "") {
                        er++;
                        return false;
                    }
                })
                if (er !== 0) {
                    toast.error(AlertMessage.MESSAGE.RATE_CARD.BOOKING_FEE_BLANK + " at row " + Number(i + 1));
                    errorCounter++;
                    return false;
                }
            }
        });

        for (let i = 0; i < this.state.rateCardData.length; i++) {
            // consoleLog("Appointmenttyoe", this.state.rateCardData[i].appointmentType)
            if (i !== this.state.rateCardData.length - 1) {
                if (this.state.rateCardData[i].appointmentType === this.state.rateCardData[i + 1].appointmentType &&
                    this.state.rateCardData[i].industryType === this.state.rateCardData[i + 1].industryType) {
                    toast.error(AlertMessage.MESSAGE.RATE_CARD.DUPLICATE_ENTRY);
                    errorCounter++;
                    return false;
                }
            }
            if (this.state.rateCardData[i].appointmentType === 'all' && this.state.rateCardData[i].industryType === 'all') {
                await this.loaderChange(true);
                let newArr = [];
                newArr.push(this.state.rateCardData[i]);
                this.setState({
                    rateCardData: newArr
                })
                await this.loaderChange(false);
                break;
            }
        }

        if (errorCounter === 0) {
            this.setState({
                isLoad: true
            })
            if (this.state.isAdd) {
                let finalData = {
                    vendorId: this.state.clientId,
                    sourceLanguage: this.state.sourceLanguage,
                    targetLanguage: this.state.targetLanguageForAdd,
                    isAllLang: this.state.isAllLang ? "1" : "0",
                    rateCardData: this.state.rateCardData
                }
                // consoleLog("Final Submit Rate Card Data::>>>>", finalData);
                // let res = await ApiCall("addVendorRatecardNew", finalData);
                let res = await ApiCall("addGlobalRatecard", finalData);
                if (
                    res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                    res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
                ) {
                    this.setState({
                        isLoad: false
                    })
                    toast.success(AlertMessage.MESSAGE.RATE_CARD.ADD_RATE_SUCCESS);
                    this.closeAddInterpretationModal();
                    this.resetRateCard();
                    let obj = {
                        vendorId: this.state.clientId
                    }
                    this.getRateList(obj);
                }

            } else {
                let temp = [];
                temp.push(this.state.targetLanguage)
                let finalData = {
                    vendorId: this.state.clientId,
                    sourceLanguage: this.state.sourceLanguage.toString(),
                    targetLanguage: temp,
                    rateCardData: this.state.rateCardData,
                    isAllLang: this.state.isAllLang ? "1" : "0",
                }
                // consoleLog("Final Submit Rate Card Data::>>>>", finalData);
                // let res = await ApiCall("addVendorRatecard", finalData);
                let res = await ApiCall("addGlobalRatecard", finalData);
                if (
                    res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                    res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
                ) {
                    this.setState({
                        isLoad: false
                    })
                    toast.success(AlertMessage.MESSAGE.RATE_CARD.ADD_RATE_SUCCESS);
                    this.closeInterpretationModal();
                    this.resetRateCard();
                    let obj = {
                        vendorId: this.state.clientId
                    }
                    this.getRateList(obj);
                }
            }
        }
    }

    resetRateCard = () => {
        this.setState({
            rateCardData: [
                {
                    appointmentType: "",
                    industryType: "",
                    regularRateHour: "",
                    regularRateHalfDay: "",
                    regularRateFullDay: "",
                    minimumDurationRate: "",
                    minimumDurationUnit: "",
                    billingIncrementRate: "",
                    billingIncrementUnit: "",
                    nightWeekendRate: "",
                    nightWeekendUnit: "",
                    mileageRate: [{
                        from: "",
                        to: "",
                        ratePerMile: "",
                        ratePerMileUnit: "",
                        selectedRatePerMileUnit: {}
                    }],
                    cancelRateChart: [{
                        from: "",
                        to: "",
                        rate: "",
                        rateUnit: "Perc",
                        selectedRatePerMileUnit: {}
                    }],
                    cancelRate: "",
                    cancelUnit: "",
                    rushPolicyRate: "",
                    rushPolicyUnit: "",
                    rushFeeRate: "",
                    rushFeeUnit: "",
                    disableAppointment: false,
                    disableJob: false,
                    selectedAppointmentType: {},
                    selectedIndustryType: {}
                }
            ]
        })
    }

    /* cancel Rate chart modal Function Start */

    formCancelRateChange = (e, pos) => {
        let arr = this.state.cancelFeeArr;
        if (decimalValue(e.target.value)) {
            arr[pos].from = e.target.value;
            this.setState({
                cancelFeeArr: arr
            })
        }
    }

    toCancelRateChange = (e, pos) => {
        let arr = this.state.cancelFeeArr;
        if (decimalValue(e.target.value)) {
            arr[pos].to = e.target.value;
            this.setState({
                cancelFeeArr: arr
            })
        }
    }

    cancelRateChange = (e, pos) => {
        let arr = this.state.cancelFeeArr;
        if (decimalValue(e.target.value)) {
            arr[pos].rate = e.target.value;
            this.setState({
                cancelFeeArr: arr
            })
        }
    }

    cancelRateUnitChange = (value, pos) => {
        let arr = this.state.cancelFeeArr;
        arr[pos].rateUnit = value.value;
        arr[pos].selectedRatePerMileUnit = value;
        this.setState({
            cancelFeeArr: arr
        })
    }

    removeCancelFeeRange = (pos) => {
        let arr = this.state.cancelFeeArr;
        arr.splice(pos, 1);
        this.setState({
            cancelFeeArr: arr
        })
    }

    addNewCancelFeeRange = () => {
        let arr = this.state.cancelFeeArr;
        arr.push({
            from: "",
            to: "",
            rate: "",
            rateUnit: "",
            selectedRatePerMileUnit: {}
        })
        this.setState({
            cancelFeeArr: arr
        })
    }

    applyCancelRateBtn = () => {
        let arr = this.state.cancelFeeArr,
            errorCount = 0;
        for (let i = 0; i < arr.length - 1; i++) {
            for (let j = i + 1; j < arr.length; j++) {
                if (this.compareTwoMilerange(arr[i], arr[j])) {
                    consoleLog("error");
                    toast.error("Error in range");
                    errorCount++;
                }
            }
        }

        if (errorCount > 0) {
            toast.error("Please enter valid range");
        } else {
            let brr = this.state.rateCardData;
            brr[this.state.cardRowPosition].cancelRateChart = arr;
            this.setState({
                rateCardData: brr,
                cancelFeeArr: []
            });
            this.closeCancelRateModal();
        }
    }

    /* cancel Rate chart modal Function End */

    rateCardModal = async (rateCardId, source, target) => {
        // console.log("Source >>> ", source, "  >>>> Target >>> ", target)
        this.state.allLanguageArr.map((aa) => {
            if (aa.value === source) {
                this.setState({
                    selectedSourceLanguage: aa
                })
            }
        });

        if (target === 0) {
            let temp = {
                label: "All",
                value: "all"
            }
            await this.loaderChange(true);
            this.setState({
                selectedTargetLanguage: temp,
                targetLanguage: temp.value,
                isAllLang: true
            })
            await this.loaderChange(false);
        } else {
            this.state.allTargetLanguageForAdd.map((aa) => {
                if (aa.value == target) {
                    // consoleLog("Selected Target Language >>> ", aa)
                    this.setState({
                        selectedTargetLanguage: aa,
                        targetLanguage: target,
                        isAllLang: false
                    })
                }
            });
        }

        let obj = {
            rateCardId: rateCardId,
            vendorId: this.state.clientId,
            serviceId: 45,
            sourceLanguage: source.toString(),
            targetLanguage: target.toString()
        }
        // let res = await ApiCall("getRatecardByLanguage", obj);
        let res = await ApiCall("getGlobalRatecardByLanguage", obj);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            consoleLog("Edit view of rate card data::", payload.data.ratecardInfo)
            let arr = [];
            if (payload.data.ratecardInfo.rateCardData.length > 0) {
                payload.data.ratecardInfo.rateCardData.map((data) => {
                    let selectedAppointment = {},
                        selectedIndustryType = {},
                        selectedMinimumDurationUnit = {},
                        selectedBillingIncrementUnit = {},
                        selectedNightWeekendUnit = {},
                        selectedCancelUnit = {},
                        selectedRushPolicyUnit = {},
                        selectedRushFeeUnit = {},
                        mileageRate = [],
                        cancelFeeArr = [];

                    this.state.allAppointmentType.map((ap) => {
                        if (data.appointmentType === ap.value) {
                            selectedAppointment = ap;
                        }
                    });

                    this.state.allJobType.map((jb) => {
                        if (data.industryType === jb.value) {
                            selectedIndustryType = jb;
                        }
                    });

                    hdfArr.map((aa) => {
                        if (data.minimumDurationUnit === aa.value) {
                            selectedMinimumDurationUnit = aa;
                        }
                    });

                    mhArr.map((aa) => {
                        if (data.billingIncrementUnit === aa.value) {
                            selectedBillingIncrementUnit = aa;
                        }
                    });

                    mhfArr.map((aa) => {
                        if (data.nightWeekendUnit === aa.value) {
                            selectedNightWeekendUnit = aa;
                        }
                    });

                    hdArr.map((aa) => {
                        if (data.cancelUnit === aa.value) {
                            selectedCancelUnit = aa;
                        }
                    });

                    hdArr.map((aa) => {
                        if (data.rushPolicyUnit === aa.value) {
                            selectedRushPolicyUnit = aa;
                        }
                    });

                    mhfArr.map((aa) => {
                        if (data.rushFeeUnit === aa.value) {
                            selectedRushFeeUnit = aa;
                        }
                    });

                    if (data.mileageRate.length > 0) {
                        data.mileageRate.map((mm) => {
                            let ratePerMileUnit = mm.rateUnit !== undefined || mm.rateUnit !== null ? mm.rateUnit : "",
                                selectedRatePerMileUnit = {};

                            rpmArr.map((aa) => {
                                if (mm.rateUnit === aa.value) {
                                    selectedRatePerMileUnit = aa;
                                }
                            });
                            mileageRate.push({
                                from: mm.fromMile.toString(),
                                to: mm.toMile.toString(),
                                ratePerMile: mm.rate.toString(),
                                ratePerMileUnit: ratePerMileUnit,
                                selectedRatePerMileUnit: selectedRatePerMileUnit
                            })
                        })
                    } else {
                        mileageRate.push({
                            from: "",
                            to: "",
                            ratePerMile: "",
                            ratePerMileUnit: "",
                            selectedRatePerMileUnit: {}
                        })
                    }

                    if (data.cancelRateChart.length > 0) {
                        data.cancelRateChart.map((mm) => {
                            let ratePerMileUnit = mm.rateUnit !== undefined || mm.rateUnit !== null ? mm.rateUnit : "",
                                selectedRatePerMileUnit = {};

                            mhfArr.map((aa) => {
                                if (mm.rateUnit === aa.value) {
                                    selectedRatePerMileUnit = aa;
                                }
                            });
                            cancelFeeArr.push({
                                from: mm.fromHour.toString(),
                                to: mm.toHour.toString(),
                                rate: mm.rate.toString(),
                                rateUnit: "Perc",
                                selectedRatePerMileUnit: selectedRatePerMileUnit
                            })
                        })
                    } else {
                        cancelFeeArr.push({
                            from: "",
                            to: "",
                            rate: "",
                            rateUnit: "Perc",
                            selectedRatePerMileUnit: {}
                        })
                    }

                    arr.push({
                        appointmentType: data.appointmentType,
                        selectedAppointmentType: selectedAppointment,
                        industryType: data.industryType,
                        selectedIndustryType: selectedIndustryType,
                        regularRateHour: data.regularRateHour,
                        regularRateHalfDay: data.regularRateHalfDay,
                        regularRateFullDay: data.regularRateFullDay,
                        minimumDurationRate: data.minimumDurationRate,
                        minimumDurationUnit: data.minimumDurationUnit,
                        selectedMinimumDurationUnit: selectedMinimumDurationUnit,
                        billingIncrementRate: data.billingIncrementRate,
                        billingIncrementUnit: data.billingIncrementUnit,
                        selectedBillingIncrementUnit: selectedBillingIncrementUnit,
                        nightWeekendRate: data.nightWeekendRate,
                        nightWeekendUnit: data.nightWeekendUnit,
                        selectedNightWeekendUnit: selectedNightWeekendUnit,
                        mileageRate: mileageRate,
                        cancelRate: data.cancelRate,
                        cancelUnit: data.cancelUnit,
                        selectedCancelUnit: selectedCancelUnit,
                        rushPolicyRate: data.rushPolicyRate,
                        rushPolicyUnit: data.rushPolicyUnit,
                        selectedRushPolicyUnit: selectedRushPolicyUnit,
                        rushFeeRate: data.rushFeeRate,
                        rushFeeUnit: data.rushFeeUnit,
                        selectedRushFeeUnit: selectedRushFeeUnit,
                        cancelRateChart: cancelFeeArr
                    })
                });
            } else {
                arr = this.state.rateCardData;
            }
            // consoleLog("RateCArdArray>>>", arr)
            this.setState({
                rateCardData: arr,
                targetLanguage: target,
                isAdd: false
            })
        }
        this.openInterpretationModal();
    }


    openInterpretationModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("interpretationModal").style.display = "block";
        document.getElementById("interpretationModal").classList.add("show");
    }

    closeInterpretationModal = () => {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("interpretationModal").style.display = "none";
        document.getElementById("interpretationModal").classList.remove("show");
        this.resetRateCard();
    }


    openAddInterpretationModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("addRateCardModal").style.display = "block";
        document.getElementById("addRateCardModal").classList.add("show");
        this.setState({
            isAdd: true
        })
    }

    closeAddInterpretationModal = () => {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("addRateCardModal").style.display = "none";
        document.getElementById("addRateCardModal").classList.remove("show");
        this.resetRateCard();
    }


    openMileageModal = (pos) => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("mileage-modal").style.display = "block";
        document.getElementById("mileage-modal").classList.add("show");
        this.setState({
            cardRowPosition: pos,
            bookingFeeArr: this.state.rateCardData[pos].mileageRate
        });
    }

    closeMileageModal = () => {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("mileage-modal").style.display = "none";
        document.getElementById("mileage-modal").classList.remove("show");
        this.setState({
            cardRowPosition: 0
        })
    }

    openCancelRateModal = (pos) => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("cancelRate-modal").style.display = "block";
        document.getElementById("cancelRate-modal").classList.add("show");
        this.setState({
            cardRowPosition: pos,
            cancelFeeArr: this.state.rateCardData[pos].cancelRateChart
        });
    }

    closeCancelRateModal = () => {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("cancelRate-modal").style.display = "none";
        document.getElementById("cancelRate-modal").classList.remove("show");
        this.setState({
            cardRowPosition: 0
        })
    }

    removeObject = (i) => {
        let arr = this.state.rateCardData;
        arr.splice(i, 1);
        this.setState({
            rateCardData: arr
        })
    }

    addBookingFeeRow = (pos) => {
        let arr = this.state.rateCardData;
        arr[pos].mileageRate.push({
            form: "",
            to: "",
            ratePerMile: ""
        });
        this.setState({
            rateCardData: arr
        })

    }

    addNewBookingRange = () => {
        let arr = this.state.bookingFeeArr;
        arr.push({
            from: "",
            to: "",
            ratePerMile: ""
        })
        this.setState({
            bookingFeeArr: arr
        })
    }

    removeBookingFeeRow = (pos) => {
        let arr = this.state.rateCardData;
        if (arr[this.state.cardRowPosition].mileageRate.length > 1) {
            arr[this.state.cardRowPosition].mileageRate.splice(pos, 1)
        } else {
            toast.error("Atleast required one booking fee");
        }
        this.setState({
            rateCardData: arr
        });
    }

    removeBookingRange = (pos) => {
        let arr = this.state.bookingFeeArr;
        arr.splice(pos, 1);
        this.setState({
            bookingFeeArr: arr
        })
    }

    formMileage = (e, pos) => {
        // let arr = this.state.rateCardData;
        // if (decimalValue(e.target.value)) {
        //     arr[this.state.cardRowPosition].mileageRate[pos].form = e.target.value;
        //     this.setState({
        //         rateCardData: arr
        //     });
        // }
        let arr = this.state.bookingFeeArr;
        if (decimalValue(e.target.value)) {
            arr[pos].from = e.target.value;
            this.setState({
                bookingFeeArr: arr
            })
        }
    }

    toMileage = (e, pos) => {
        // let arr = this.state.rateCardData;
        // if (decimalValue(e.target.value)) {
        //     arr[this.state.cardRowPosition].mileageRate[pos].to = e.target.value;
        //     this.setState({
        //         rateCardData: arr
        //     });
        // }
        let arr = this.state.bookingFeeArr;
        if (decimalValue(e.target.value)) {
            arr[pos].to = e.target.value;
            this.setState({
                bookingFeeArr: arr
            })
        }
    }

    ratePerMile = (e, pos) => {
        // let arr = this.state.rateCardData;
        // if (decimalValue(e.target.value)) {
        //     arr[this.state.cardRowPosition].mileageRate[pos].ratePerMile = e.target.value;
        //     this.setState({
        //         rateCardData: arr
        //     });
        // }
        let arr = this.state.bookingFeeArr;
        if (decimalValue(e.target.value)) {
            arr[pos].ratePerMile = e.target.value;
            this.setState({
                bookingFeeArr: arr
            })
        }
    }

    ratePerMileUnitChange = (value, pos) => {
        let arr = this.state.bookingFeeArr;
        arr[pos].ratePerMileUnit = value.value;
        arr[pos].selectedRatePerMileUnit = value;
        this.setState({
            bookingFeeArr: arr
        })
    }

    compareTwoMilerange = (a, b) => {
        if (a.from <= b.from && a.to >= b.from && a.from >= b.to && a.to <= b.to) {
            return true;
        } else {
            return false;
        }
    }

    applyMileageBtn = () => {
        let arr = this.state.bookingFeeArr,
            errorCount = 0;
        for (let i = 0; i < arr.length - 1; i++) {
            for (let j = i + 1; j < arr.length; j++) {
                if (this.compareTwoMilerange(arr[i], arr[j])) {
                    consoleLog("error");
                    toast.error("Error in range");
                    errorCount++;
                }
                //  else {
                //     toast.success("All Range chec  kout fine");
                // }
            }
        }

        if (errorCount > 0) {
            toast.error("Please enter valid mileage range");
        } else {
            let brr = this.state.rateCardData;
            brr[this.state.cardRowPosition].mileageRate = arr;
            this.setState({
                rateCardData: brr,
                bookingFeeArr: []
            });
            this.closeMileageModal();
        }
    }


    // .............pagination function..........
    clickChange = (e) => {
        this.setState({
            current_page: e.target.value,
        });
    };

    // This is goes to the previous page
    exLeft = async () => {
        this.setState({
            current_page: 1,
        });
        let data = {
            vendorId: this.state.clientId,
            serviceId: 45,
            limit: this.state.limit.toString(),
            offset: '0',
        };

        this.getRateList(data);
    };

    // This is goes to the first page
    prev = async () => {
        let currentPage = this.state.current_page;
        if (currentPage > 1) {
            currentPage--;
            this.setState({
                current_page: currentPage,
            });
            let data = {
                vendorId: this.state.clientId,
                serviceId: 45,
                limit: this.state.limit.toString(),
                offset: JSON.stringify((currentPage - 1) * this.state.limit),
            };

            this.getRateList(data);
        }
    };

    // This is goes to the next page
    next = async () => {
        let currentPage = this.state.current_page;
        let totalPage = this.state.total_page;
        if (currentPage < totalPage) {
            currentPage++;
            this.setState({
                current_page: currentPage,
            });
            let data = {
                vendorId: this.state.clientId,
                serviceId: 45,
                limit: this.state.limit.toString(),
                offset: JSON.stringify((currentPage - 1) * this.state.limit),
            };

            this.getRateList(data);
        }
    };

    // This is goes to the last page
    exRigth = async () => {
        let totalPage = this.state.total_page;
        this.setState({
            current_page: totalPage,
        });
        let data = {
            vendorId: this.state.clientId,
            serviceId: 45,
            limit: this.state.limit.toString(),
            offset: JSON.stringify((totalPage - 1) * this.state.limit),
        };

        this.getRateList(data);
    };

    onTabClick = (type) => {
        this.setState({
            clientId: type
        });
        let data = {
            vendorId: type,
            serviceId: 45,
            limit: this.state.limit,
            offset: this.state.offset
        }
        this.getRateList(data)
    }

    render() {
        return (
            <React.Fragment>
                <div class="component-wrapper" hidden={!this.state.isLoad}>
                    <ReactLoader />
                </div>
                <div className="component-wrapper" hidden={this.state.isLoad}>
                    <ToastContainer hideProgressBar theme='colored' />
                    <div className="listing-component-app">
                        <div> <div
                            className="vn_frm"
                            style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
                        >
                            {" "}
                            <Link to="/adminDashboard">Dashboard</Link> / Settings / Rate Card
                        </div>
                            <div className="vendor-info _fl sdw">
                                <div className="vn-form _fl">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="_fl verificaiton-doc-tab ven">
                                                <ul>
                                                    <li
                                                        className="active"
                                                        data-related="tble-data-a"
                                                        onClick={() => {
                                                            this.onTabClick("0");
                                                        }}
                                                    >
                                                        Client
                                                    </li>
                                                    <li
                                                        data-related="tble-data-b"
                                                        onClick={() => {
                                                            this.onTabClick("-1");
                                                        }}
                                                    >
                                                        Vendor
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-4" />
                                        <div className="col-md-4 ">
                                            {/* <div className="vn_frm rt">
                                                <InputText
                                                    placeholder="Search"
                                                    className="inputfield"
                                                    value={this.state.searchValue}
                                                    onTextChange={(value) => {
                                                        this.handleVendorChange(value);
                                                    }}
                                                />
                                            </div> */}

                                            <div className="md-btn_rate text-center">
                                                <a href='javascript:void(0)' className="children" onClick={() => this.openAddInterpretationModal()}>ADD RATE CARD</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row"> */}
                                    {/* <div className="col-md-6 rateList"> */}
                                    {/* <div className="_fl verificaiton-doc-tab">
                                                <ul>
                                                    <li className="active" onClick={() => { this.onTabClick("interpretation") }}>INTERPRETATION</li>
                                                    <li onClick={() => { this.onTabClick("translation") }}>TRANSLATION</li>
                                                    <li onClick={() => { this.onTabClick("training") }}>TRAINING</li>
                                                </ul>
                                            </div> */}
                                    {/* </div> */}
                                    {/* <div className="col-md-2" />
                                        <div className="col-md-4">

                                        </div> */}
                                    {/* </div> */}
                                </div>
                            </div>

                            <div className="table-filter-app">
                                <div className="filter-pagination">
                                    <button className="prev_btn" onClick={this.exLeft}></button>
                                    <button className="prv_btn" onClick={this.prev}>
                                        {" "}
                                        {"<"}
                                    </button>
                                    <span
                                        className="num mast-prev-num"
                                        onChange={(e) => this.clickChange(e)}
                                    >
                                        {this.state.current_page}
                                    </span>
                                    <button className="nxt_btn" onClick={this.next}>
                                        {">"}
                                    </button>
                                    <button className="next_btn" onClick={this.exRigth}></button>
                                </div>
                                <div className="table-filter-box">
                                    <div className="tble-short">
                                        <span className="lbl">Display</span>
                                        <div
                                            class="dropdwn"
                                            style={{
                                                width: "70px",
                                                fontSize: "12px",
                                                textAlign: "center",
                                            }}
                                        >
                                            <PaginationDropdown
                                                optionData={CommonData.COMMON.DISPLAY_ARR}
                                                value={this.state.selectedDisplayData}
                                                placeholder="Select"
                                                onSelectChange={(value) => {
                                                    this.onChangeLimit(value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div hidden={!this.state.isInterpretation}>
                            <div className="table-listing-app" >
                                <div className="table-responsive">
                                    {/* <div className="table-responsive_cus table-style-a"> */}
                                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "20%" }}>Source Language</th>
                                                <th style={{ width: "20%" }}>Target Language</th>
                                                <th style={{ width: "20%" }}>Appointment Type</th>
                                                <th style={{ width: "20%" }}>Job Type</th>
                                                <th style={{ width: "20%" }}>Action</th>
                                            </tr>
                                            {this.state.interpretationData.length > 0 ? <React.Fragment>
                                                {this.state.interpretationData.map((item, key) => (
                                                    <tr>
                                                        <td colSpan="11">
                                                            <div className="tble-row">
                                                                <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: "20%" }}>{item.sourceLanguage}</td>
                                                                            <td style={{ width: "20%" }}>{item.targetLanguage}</td>
                                                                            <td style={{ width: "20%" }}>
                                                                                {item.appointmentType}
                                                                            </td>
                                                                            <td style={{ width: "20%" }}>{item.industryType}</td>
                                                                            <td style={{ width: "20%" }}>
                                                                                <div className="tbl-editing-links">
                                                                                    <button className="tr-toggle" onClick={() => this.rateCardModal(item.id, item.sourceLanguageId, item.targetLanguageId)}>
                                                                                        <img src={ImageName.IMAGE_NAME.EDIT_SQUARE} />
                                                                                    </button>

                                                                                    <button className="tr-toggle" onClick={() => this.deleteRate(item.id)}><img src={ImageName.IMAGE_NAME.TRASH_BTN} /></button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}</React.Fragment> :

                                                <tr style={{ textAlign: "center" }}>
                                                    <td colSpan="5">
                                                        <center style={{ fontSize: "20px" }}>
                                                            No data found !!!
                                                        </center>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="interpretationModal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="filter-head _fl document-hd">
                                <h3 className="text-center center-text">Edit Rate Card</h3>
                                <button type="button" className="close" onClick={this.closeInterpretationModal}>X</button>
                            </div>
                            <div className="modal-body">
                                <div className="form-wrap">
                                    <div className="sdw _fl _mg4">
                                        <h4 className="h4-grey-text">Client: {this.state.clientName}</h4>
                                        <div className="form-step-row m30 _fl">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-stp">
                                                        <span>Source Language</span>
                                                        <div className="selectbox" style={{ width: "60%" }}>
                                                            <SelectBox
                                                                optionData={this.state.allLanguageArr}
                                                                value={this.state.selectedSourceLanguage}
                                                                placeholder="Select"
                                                                onSelectChange={(value) => {
                                                                    this.onSourceLangChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4"></div>
                                                <div className="col-md-4">
                                                    <div className="form-stp">
                                                        <span>Target Language</span>
                                                        <div className="selectbox" style={{ width: "60%" }}>
                                                            <SelectBox
                                                                optionData={this.state.allTargetLanguageForAdd}
                                                                value={this.state.selectedTargetLanguage}
                                                                placeholder="Select"
                                                                onSelectChange={(value) => {
                                                                    this.onTargetLangChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-listing-app card">
                                    <div className="table-responsive" style={{ paddingBottom: "10%" }}>
                                        <table width="100%" border="0" cellpadding="0" cellSpacing="0">
                                            <tr>
                                                <th style={{ width: "7%" }}>Appointment Type</th>
                                                <th style={{ width: "7%", paddingLeft: "2%" }}>Job Type</th>
                                                <th style={{ width: "16%", paddingLeft: "2%" }}>Regular Rate ($/Unit)</th>
                                                <th style={{ width: "10%", paddingLeft: "2%" }}>Minimum Duration</th>
                                                <th style={{ width: "10%", paddingLeft: "2%" }}>Billing Increment</th>
                                                <th style={{ width: "10%", paddingLeft: "2%" }}>Night/weekends</th>
                                                <th style={{ width: "10%", paddingLeft: "2%" }}>Cancellation Policy</th>
                                                <th style={{ width: "10%", paddingLeft: "2%" }}>Rush Policy</th>
                                                <th style={{ width: "10%", paddingLeft: "2%" }}>Rush Fee</th>
                                                <th style={{ width: "6%", paddingLeft: "2%" }}>Booking Fee</th>
                                                <th style={{ width: "4%", paddingLeft: "2%" }}></th>
                                            </tr>
                                            {this.state.rateCardData.map((item, index) =>
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td style={{ width: "7%" }}>
                                                            <SmallSelectBox
                                                                optionData={this.state.allAppointmentType}
                                                                value={item.selectedAppointmentType}
                                                                placeholder="Select"
                                                                onSelectChange={(value) => {
                                                                    this.appointmentTypeChange(value, index);
                                                                }}
                                                            >
                                                            </SmallSelectBox>
                                                        </td>
                                                        <td style={{ width: "7%", paddingLeft: "1%" }}>
                                                            <SmallSelectBox
                                                                optionData={this.state.allJobType}
                                                                value={item.selectedIndustryType}
                                                                placeholder="Select"
                                                                onSelectChange={(value) => {
                                                                    this.handleJobType(value, index);
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{ width: "16%", paddingLeft: "1%" }}>
                                                            <div className="row no-gutters" style={{ padding: "5%" }}>
                                                                <div className="col-md-4">
                                                                    <input
                                                                        type="text"
                                                                        value={item.regularRateHour}
                                                                        name=""
                                                                        placeholder="per hour"
                                                                        className="in-field3_vr"
                                                                        style={{ cursor: "pointer" }}
                                                                        onChange={(e) => this.regularRateHourChange(index, e)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input
                                                                        type="text"
                                                                        value={item.regularRateHalfDay}
                                                                        name=""
                                                                        placeholder="Half day"
                                                                        className="in-field3_vr"
                                                                        style={{ cursor: "pointer" }}
                                                                        onChange={(e) => this.handleRegularRateHalfDay(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input
                                                                        type="text"
                                                                        value={item.regularRateFullDay}
                                                                        name=""
                                                                        placeholder="Full Day"
                                                                        className="in-field3_vr"
                                                                        style={{ cursor: "pointer" }}
                                                                        onChange={(e) => this.handleRegularRateFullDay(e, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "10%", paddingLeft: "2%" }}>
                                                            <div className="row no-gutters" style={{ padding: "2%" }}>
                                                                <div className="col-md-4">
                                                                    <input type="text"
                                                                        value={item.minimumDurationRate}
                                                                        name=""
                                                                        placeholder=""
                                                                        className="in-field3_vr"
                                                                        onChange={(e) => this.handleMinimumDurationrate(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <SmallSelectBox
                                                                        optionData={hdfArr}
                                                                        value={item.selectedMinimumDurationUnit}
                                                                        placeholder="Select"
                                                                        onSelectChange={(value) => {
                                                                            this.handleMinimumDurationUnit(value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "10%", paddingLeft: "2%" }}>
                                                            <div className="row no-gutters" style={{ padding: "1%" }}>
                                                                <div className="col-md-4">
                                                                    <input
                                                                        type="text"
                                                                        value={item.billingIncrementRate}
                                                                        name=""
                                                                        placeholder=""
                                                                        className="in-field3_vr"
                                                                        onChange={(e) => this.handleBillingRate(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <SmallSelectBox
                                                                        optionData={mhArr}
                                                                        value={item.selectedBillingIncrementUnit}
                                                                        placeholder="Select"
                                                                        onSelectChange={(value) => {
                                                                            this.handleBillingUnit(value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "10%", paddingLeft: "2%" }}>
                                                            <div className="row no-gutters" style={{ padding: "2%" }}>
                                                                <div className="col-md-4">
                                                                    <input
                                                                        type="text"
                                                                        value={item.nightWeekendRate}
                                                                        placeholder="$"
                                                                        className="in-field3_vr"
                                                                        onChange={(e) => this.handleNightPerWeekendRate(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <SmallSelectBox
                                                                        optionData={mhfArr}
                                                                        value={item.selectedNightWeekendUnit}
                                                                        placeholder="Select"
                                                                        onSelectChange={(value) => {
                                                                            this.handleNightPerWeekendUnit(value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "10%", paddingLeft: "2%" }}>
                                                            {/* <div className="row no-gutters" style={{ paddingLeft: "1%" }}>
                                                                <div className="col-md-4">
                                                                    <input type="text"
                                                                        value={item.cancelRate}
                                                                        name=""
                                                                        placeholder="$"
                                                                        className="in-field3_vr"
                                                                        onChange={(e) => this.handleCancelRate(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <SmallSelectBox
                                                                        optionData={hdArr}
                                                                        value={item.selectedCancelUnit}
                                                                        placeholder="Select"
                                                                        onSelectChange={(value) => {
                                                                            this.handleCancelUnit(value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div> */}

                                                            <div className="row no-gutters" style={{ padding: "2%" }}>
                                                                <div className="col-md-12">
                                                                    <Button variant="text" onClick={() => this.openCancelRateModal(index)}>View</Button>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "10%", paddingLeft: "2%" }}>
                                                            <div className="row no-gutters" style={{ paddingLeft: "1%" }}>
                                                                <div className="col-md-4">
                                                                    <input type="text"
                                                                        value={item.rushPolicyRate}
                                                                        name=""
                                                                        placeholder=""
                                                                        className="in-field3_vr"
                                                                        onChange={(e) => this.handleRushPolicyrate(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <SmallSelectBox
                                                                        optionData={hdArr}
                                                                        value={item.selectedRushPolicyUnit}
                                                                        placeholder="Select"
                                                                        onSelectChange={(value) => {
                                                                            this.handleRushPolicyUnit(value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "10%", paddingLeft: "2%" }}>
                                                            <div className="row no-gutters" style={{ paddingLeft: "1%" }}>
                                                                <div className="col-md-4">
                                                                    <input type="text"
                                                                        value={item.rushFeeRate}
                                                                        name=""
                                                                        placeholder="$"
                                                                        className="in-field3_vr"
                                                                        onChange={(e) => this.handleRushFeeRate(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <SmallSelectBox
                                                                        optionData={mhfArr}
                                                                        value={item.selectedRushFeeUnit}
                                                                        placeholder="Select"
                                                                        onSelectChange={(value) => {
                                                                            this.handleRushFeeUnit(value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "6%", paddingLeft: "2%" }}>
                                                            <div className="row no-gutters" style={{ padding: "2%" }}>
                                                                <div className="col-md-12">
                                                                    {item.appointmentType == 63 || item.appointmentType === "all" ?
                                                                        <Button variant="text" onClick={() => this.openMileageModal(index)}>View</Button>
                                                                        : <p>N/A</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "4%", paddingLeft: "2%" }}>
                                                            {index > 0 || this.state.rateCardData.length > 1 ?
                                                                <img src={ImageName.IMAGE_NAME.CANCEL_BTN} style={{ cursor: "pointer" }} alt="" onClick={() => this.removeObject(index)} />
                                                                : <React.Fragment></React.Fragment>
                                                            }
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )}
                                        </table>
                                    </div>
                                </div>
                                <div className="_button-style _fl text-center" style={{ paddingBottom: "5%" }}>
                                    <a href="javascript:void(0)" className="blue-btn" onClick={this.addNewRow}>Add More</a>
                                </div>
                                {/* <div className="b-i-s _fl text-right _button-style m30">
                                    <a href="#" className="blue-btn">Add language Pair</a>
                                </div> */}
                                <div className="_button-style _fl text-center">
                                    <a href="javascript:void(0)" className="grey-btn" style={{ textDecoration: "none" }} onClick={this.closeInterpretationModal}>cancel</a>
                                    {/* <a href="#" className="blue-btn" >save</a> */}
                                    <a href="javascript:void(0)" className="blue-btn" onClick={() => this.onSubmitRate()}>SAVE</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="addRateCardModal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="addRateCardModal" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="filter-head _fl document-hd">
                                <h3 className="text-center center-text">Add Rate Card</h3>
                                <button type="button" className="close" onClick={this.closeAddInterpretationModal}>X</button>
                            </div>
                            <div className="modal-body">
                                <div class="form-wrap" hidden={!this.state.isLoad}>
                                    <ReactLoader />
                                </div>
                                <div className="form-wrap" hidden={this.state.isLoad}>
                                    <div className="sdw _fl _mg4">
                                        <h4 className="h4-grey-text">Client: {this.state.clientName}</h4>
                                        <div className="form-step-row m30 _fl">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-stp">
                                                        <span>Source Language</span>
                                                        <div className="selectbox" style={{ width: "60%" }}>
                                                            <SelectBox
                                                                optionData={this.state.allLanguageArr}
                                                                value={this.state.selectedSourceLanguage}
                                                                placeholder="Select"
                                                                onSelectChange={(value) => {
                                                                    this.onSourceLangChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4"></div>
                                                <div className="col-md-4">
                                                    <div className="form-stp">
                                                        <span>Target Language</span>
                                                        <div className="selectbox" style={{ width: "60%" }}>
                                                            <MultiSelectBox
                                                                optionData={this.state.allTargetLanguageForAdd}
                                                                value={this.state.selectedtargetLanguageForAdd}
                                                                placeholder="Select"
                                                                onSelectChange={(value) => {
                                                                    this.onTargetLangChangeForAdd(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-listing-app card" hidden={this.state.isLoad}>
                                    <div className="table-responsive" style={{ paddingBottom: "10%" }}>
                                        <table width="100%" border="0" cellpadding="0" cellSpacing="0">
                                            <tr>
                                                <th style={{ width: "7%" }}>Appointment Type</th>
                                                <th style={{ width: "7%", paddingLeft: "2%" }}>Job Type</th>
                                                <th style={{ width: "16%", paddingLeft: "2%" }}>Regular Rate ($/Unit)</th>
                                                <th style={{ width: "10%", paddingLeft: "2%" }}>Minimum Duration</th>
                                                <th style={{ width: "10%", paddingLeft: "2%" }}>Billing Increment</th>
                                                <th style={{ width: "10%", paddingLeft: "2%" }}>Night/weekends</th>
                                                <th style={{ width: "10%", paddingLeft: "2%" }}>Cancellation Policy</th>
                                                <th style={{ width: "10%", paddingLeft: "2%" }}>Rush Policy</th>
                                                <th style={{ width: "10%", paddingLeft: "2%" }}>Rush Fee</th>
                                                <th style={{ width: "6%", paddingLeft: "2%" }}>Booking Fee</th>
                                                <th style={{ width: "4%", paddingLeft: "2%" }}></th>
                                            </tr>
                                            {this.state.rateCardData.map((item, index) =>
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td style={{ width: "7%" }}>
                                                            <SmallSelectBox
                                                                optionData={this.state.allAppointmentType}
                                                                value={item.selectedAppointmentType}
                                                                placeholder="Select"
                                                                onSelectChange={(value) => {
                                                                    this.appointmentTypeChange(value, index);
                                                                }}
                                                            >
                                                            </SmallSelectBox>
                                                        </td>
                                                        <td style={{ width: "7%", paddingLeft: "1%" }}>
                                                            <SmallSelectBox
                                                                optionData={this.state.allJobType}
                                                                value={item.selectedIndustryType}
                                                                placeholder="Select"
                                                                onSelectChange={(value) => {
                                                                    this.handleJobType(value, index);
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{ width: "16%", paddingLeft: "1%" }}>
                                                            <div className="row no-gutters" style={{ padding: "5%" }}>
                                                                <div className="col-md-4">
                                                                    <input
                                                                        type="text"
                                                                        value={item.regularRateHour}
                                                                        name=""
                                                                        placeholder="per hour"
                                                                        className="in-field3_vr"
                                                                        style={{ cursor: "pointer" }}
                                                                        onChange={(e) => this.regularRateHourChange(index, e)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input
                                                                        type="text"
                                                                        value={item.regularRateHalfDay}
                                                                        name=""
                                                                        placeholder="Half day"
                                                                        className="in-field3_vr"
                                                                        style={{ cursor: "pointer" }}
                                                                        onChange={(e) => this.handleRegularRateHalfDay(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input
                                                                        type="text"
                                                                        value={item.regularRateFullDay}
                                                                        name=""
                                                                        placeholder="Full Day"
                                                                        className="in-field3_vr"
                                                                        style={{ cursor: "pointer" }}
                                                                        onChange={(e) => this.handleRegularRateFullDay(e, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "10%", paddingLeft: "2%" }}>
                                                            <div className="row no-gutters" style={{ padding: "2%" }}>
                                                                <div className="col-md-4">
                                                                    <input type="text"
                                                                        value={item.minimumDurationRate}
                                                                        name=""
                                                                        placeholder=""
                                                                        className="in-field3_vr"
                                                                        onChange={(e) => this.handleMinimumDurationrate(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <SmallSelectBox
                                                                        optionData={hdfArr}
                                                                        value={item.selectedMinimumDurationUnit}
                                                                        placeholder="Select"
                                                                        onSelectChange={(value) => {
                                                                            this.handleMinimumDurationUnit(value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "10%", paddingLeft: "2%" }}>
                                                            <div className="row no-gutters" style={{ padding: "1%" }}>
                                                                <div className="col-md-4">
                                                                    <input
                                                                        type="text"
                                                                        value={item.billingIncrementRate}
                                                                        name=""
                                                                        placeholder=""
                                                                        className="in-field3_vr"
                                                                        onChange={(e) => this.handleBillingRate(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <SmallSelectBox
                                                                        optionData={mhArr}
                                                                        value={item.selectedBillingIncrementUnit}
                                                                        placeholder="Select"
                                                                        onSelectChange={(value) => {
                                                                            this.handleBillingUnit(value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "10%", paddingLeft: "2%" }}>
                                                            <div className="row no-gutters" style={{ padding: "2%" }}>
                                                                <div className="col-md-4">
                                                                    <input
                                                                        type="text"
                                                                        value={item.nightWeekendRate}
                                                                        placeholder="$"
                                                                        className="in-field3_vr"
                                                                        onChange={(e) => this.handleNightPerWeekendRate(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <SmallSelectBox
                                                                        optionData={mhfArr}
                                                                        value={item.selectedNightWeekendUnit}
                                                                        placeholder="Select"
                                                                        onSelectChange={(value) => {
                                                                            this.handleNightPerWeekendUnit(value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "10%", paddingLeft: "2%" }}>
                                                            {/* <div className="row no-gutters" style={{ paddingLeft: "1%" }}>
                                                                <div className="col-md-4">
                                                                    <input type="text"
                                                                        value={item.cancelRate}
                                                                        name=""
                                                                        placeholder="$"
                                                                        className="in-field3_vr"
                                                                        onChange={(e) => this.handleCancelRate(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <SmallSelectBox
                                                                        optionData={hdArr}
                                                                        value={item.selectedCancelUnit}
                                                                        placeholder="Select"
                                                                        onSelectChange={(value) => {
                                                                            this.handleCancelUnit(value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div> */}

                                                            <div className="row no-gutters" style={{ padding: "2%" }}>
                                                                <div className="col-md-12">
                                                                    <Button variant="text" onClick={() => this.openCancelRateModal(index)}>View</Button>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "10%", paddingLeft: "2%" }}>
                                                            <div className="row no-gutters" style={{ paddingLeft: "1%" }}>
                                                                <div className="col-md-4">
                                                                    <input type="text"
                                                                        value={item.rushPolicyRate}
                                                                        name=""
                                                                        placeholder=""
                                                                        className="in-field3_vr"
                                                                        onChange={(e) => this.handleRushPolicyrate(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <SmallSelectBox
                                                                        optionData={hdArr}
                                                                        value={item.selectedRushPolicyUnit}
                                                                        placeholder="Select"
                                                                        onSelectChange={(value) => {
                                                                            this.handleRushPolicyUnit(value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "10%", paddingLeft: "2%" }}>
                                                            <div className="row no-gutters" style={{ paddingLeft: "1%" }}>
                                                                <div className="col-md-4">
                                                                    <input type="text"
                                                                        value={item.rushFeeRate}
                                                                        name=""
                                                                        placeholder="$"
                                                                        className="in-field3_vr"
                                                                        onChange={(e) => this.handleRushFeeRate(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <SmallSelectBox
                                                                        optionData={mhfArr}
                                                                        value={item.selectedRushFeeUnit}
                                                                        placeholder="Select"
                                                                        onSelectChange={(value) => {
                                                                            this.handleRushFeeUnit(value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "6%", paddingLeft: "2%" }}>
                                                            <div className="row no-gutters" style={{ padding: "2%" }}>
                                                                <div className="col-md-12">
                                                                    {item.appointmentType == 63 || item.appointmentType === "all" ?
                                                                        <Button variant="text" onClick={() => this.openMileageModal(index)}>View</Button>
                                                                        : <p>N/A</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: "4%", paddingLeft: "2%" }}>
                                                            {index > 0 || this.state.rateCardData.length > 1 ?
                                                                <img src={ImageName.IMAGE_NAME.CANCEL_BTN} style={{ cursor: "pointer" }} alt="" onClick={() => this.removeObject(index)} />
                                                                : <React.Fragment></React.Fragment>
                                                            }
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )}
                                        </table>
                                    </div>
                                </div>
                                <div className="_button-style _fl text-center" style={{ paddingBottom: "5%" }} hidden={this.state.isLoad}>
                                    <a href="javascript:void(0)" className="blue-btn" onClick={this.addNewRow}>Add More</a>
                                </div>
                                {/* <div className="b-i-s _fl text-right _button-style m30">
                                    <a href="#" className="blue-btn">Add language Pair</a>
                                </div> */}
                                <div className="_button-style _fl text-center" hidden={this.state.isLoad}>
                                    <a href="javascript:void(0)" className="grey-btn" style={{ textDecoration: "none" }} onClick={this.closeAddInterpretationModal}>cancel</a>

                                    <a href="javascript:void(0)" className="blue-btn" onClick={() => this.onSubmitRate()}>SAVE</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="mileage-modal" className="modal fade modelwindow" role="dialog">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="filter-head _fl document-hd">
                                <h3 className="text-center center-text">Booking Fee</h3>
                                <button type="button" className="close" onClick={() => this.closeMileageModal()}>X</button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className='col-md-12'>
                                        <table className='dataTable_project' style={{ width: "100%" }}>
                                            <tr style={{ textAlign: "center" }}>
                                                <th>Form</th>
                                                <th>To</th>
                                                <th>Rate /Mile</th>
                                            </tr>
                                            {/* {this.state.rateCardData[this.state.cardRowPosition].mileageRate.length > 0 ? <React.Fragment>
                                                {this.state.rateCardData[this.state.cardRowPosition].mileageRate.map((data, i) => */}
                                            {this.state.bookingFeeArr.length > 0 ? <React.Fragment>
                                                {this.state.bookingFeeArr.map((data, i) =>
                                                    <React.Fragment>
                                                        <tr style={{ textAlign: "center" }}>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="in-field3"
                                                                    placeholder="in mile"
                                                                    value={data.from}
                                                                    onChange={(e) => {
                                                                        this.formMileage(e, i);
                                                                    }}
                                                                    style={{ width: "70%" }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="in-field3"
                                                                    placeholder="in mile"
                                                                    value={data.to}
                                                                    onChange={(e) => {
                                                                        this.toMileage(e, i);
                                                                    }}
                                                                    style={{ width: "70%" }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <div className='row'>
                                                                    <div className="col-md-5">
                                                                        <input
                                                                            type="text"
                                                                            className="in-field3"
                                                                            placeholder="$"
                                                                            value={data.ratePerMile}
                                                                            onChange={(e) => {
                                                                                this.ratePerMile(e, i);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='col-md-1'></div>
                                                                    <div className="col-md-6">
                                                                        <SmallSelectBox
                                                                            optionData={rpmArr}
                                                                            value={data.selectedRatePerMileUnit}
                                                                            placeholder="Select"
                                                                            onSelectChange={(value) => {
                                                                                this.ratePerMileUnitChange(value, i);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style={{
                                                                width: "8%"
                                                            }}>
                                                                <img
                                                                    src={ImageName.IMAGE_NAME.CANCEL_BTN}
                                                                    style={{ cursor: "pointer" }}
                                                                    alt=""
                                                                    onClick={() => this.removeBookingRange(i)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>)}
                                            </React.Fragment> : <React.Fragment>
                                                <td colSpan="4">
                                                    No Data Found
                                                </td>
                                            </React.Fragment>}
                                        </table>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="md-btn_rate text-center">
                                            <Stack spacing={2} direction="row" style={{ float: "right" }}>
                                                {/* <a href='javascript:void(0)' className="children" onClick={() => this.addBookingFeeRow(this.state.cardRowPosition)}>ADD</a> */}
                                                <Button variant="contained" onClick={() => this.addNewBookingRange(this.state.cardRowPosition)}>Add</Button>
                                                <Button variant="contained" onClick={() => this.applyMileageBtn()}>Apply</Button>
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="cancelRate-modal" className="modal fade modelwindow" role="dialog">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="filter-head _fl document-hd">
                                <h3 className="text-center center-text">Cancellation Policy</h3>
                                <button type="button" className="close" onClick={() => this.closeCancelRateModal()}>X</button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className='col-md-12'>
                                        <table className='dataTable_project' style={{ width: "100%" }}>
                                            <tr style={{ textAlign: "center" }}>
                                                <th>Form</th>
                                                <th>To</th>
                                                <th>Rate Info.</th>
                                            </tr>
                                            {/* {this.state.rateCardData[this.state.cardRowPosition].mileageRate.length > 0 ? <React.Fragment>
{this.state.rateCardData[this.state.cardRowPosition].mileageRate.map((data, i) => */}
                                            {this.state.cancelFeeArr.length > 0 ? <React.Fragment>
                                                {this.state.cancelFeeArr.map((data, i) =>
                                                    <React.Fragment>
                                                        <tr style={{ textAlign: "center" }}>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="in-field3"
                                                                    placeholder="in hour"
                                                                    value={data.from}
                                                                    onChange={(e) => {
                                                                        this.formCancelRateChange(e, i);
                                                                    }}
                                                                    style={{ width: "70%" }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="in-field3"
                                                                    placeholder="in hour"
                                                                    value={data.to}
                                                                    onChange={(e) => {
                                                                        this.toCancelRateChange(e, i);
                                                                    }}
                                                                    style={{ width: "70%" }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <div className='row'>
                                                                    <div className="col-md-3"></div>
                                                                    <div className="col-md-5">
                                                                        <input
                                                                            type="text"
                                                                            className="in-field3"
                                                                            placeholder=""
                                                                            value={data.rate}
                                                                            onChange={(e) => {
                                                                                this.cancelRateChange(e, i);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='col-md-1' style={{ margin: "auto" }}>%</div>
                                                                    <div className="col-md-3">
                                                                        {/* <SmallSelectBox
                                                                            optionData={mhfArr}
                                                                            value={data.selectedRatePerMileUnit}
                                                                            placeholder="Select"
                                                                            onSelectChange={(value) => {
                                                                                this.cancelRateUnitChange(value, i);
                                                                            }}
                                                                        /> */}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style={{
                                                                width: "8%"
                                                            }}>
                                                                <img
                                                                    src={ImageName.IMAGE_NAME.CANCEL_BTN}
                                                                    style={{ cursor: "pointer" }}
                                                                    alt=""
                                                                    onClick={() => this.removeCancelFeeRange(i)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>)}
                                            </React.Fragment> : <React.Fragment>
                                                <td colSpan="4">
                                                    No Data Found
                                                </td>
                                            </React.Fragment>}
                                        </table>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="md-btn_rate text-center">
                                            <Stack spacing={2} direction="row" style={{ float: "right" }}>
                                                {/* <a href='javascript:void(0)' className="children" onClick={() => this.addBookingFeeRow(this.state.cardRowPosition)}>ADD</a> */}
                                                <Button variant="contained" onClick={() => this.addNewCancelFeeRange(this.state.cardRowPosition)}>Add</Button>
                                                <Button variant="contained" onClick={() => this.applyCancelRateBtn()}>Apply</Button>
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="modal-backdrop fade"
                    id="backdrop"
                    style={{ display: "none" }}
                ></div>
            </React.Fragment>

        )
    }
}